/* Global Styles */
:root {
  --primary-color: #00acee;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

#bKashFrameWrapper {
  height: 100vh !important;
  z-index: 999999999 !important;
}
.maxwidth {
  width: 100vw !important;
  height: 100vh !important;
  background-color: rebeccapurple;
}

/* textarea {
  display: block;
  min-width: 100%;
  max-width: 100%;
  min-height: 200px;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  font-family: 'Lato', sans-serif;
} */

.ql-editor {
  min-height: 50vh;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: var(--primary-color) !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--primary-color) !important;
}

.MuiStepIcon-text {
  fill: white !important;
}

.fontFixer {
  font-family: 'Lato', sans-serif;
}

.logo {
  width: 50px;
}

.logo-100 {
  width: 100px;
}

.logo-200 {
  width: 200px;
}

.profilePic-200 {
  width: 200px;
}

.signature-200 {
  height: 200px;
}

.profile-pic-100 {
  width: 100px !important;
  border-radius: 50%;
}

.reset-pass-edit-number {
  margin-bottom: 0.5rem;
  color: #888;
}

.MuiLinearProgress-colorPrimary {
  color: var(--primary-color) !important;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Utilities */
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

/* Utilities */
.table-container {
  max-width: 95vw;
  margin: auto;
  overflow: hidden;
  /* padding: 0 2rem; */
  margin-top: -1rem;
  margin-bottom: 3rem;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.large-alt {
  font-size: 3rem;
  line-height: 1.2;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.lead-alt {
  font-size: 1.5rem;
  /* margin-bottom: 0.5rem; */
}

.mb-1 {
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.MuiAlert-standardError,
.MuiAlert-standardWarning,
.MuiAlert-standardSuccess {
  margin: 1rem 0;
}

.MuiLinearProgress-bar {
  background-color: #00acee !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: #b3e6fa !important;
}

/* Color For Switch Starts */

/* .MuiSwitch-switchBase {
  color: white;
} */

.MuiSwitch-colorPrimary.Mui-checked {
  color: #00acee !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #00acee !important;
}
/* Color For Switch Ends */

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-primary:disabled {
  background: #eeeeee;
  color: #ababab;
  pointer-events: none;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover:enabled {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Alert */
.alertStyle {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.marginInTop {
  margin-top: 0.5rem;
}

.marginInTopAndBottom {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  color: #888;
}

.form input[type='checkbox'] {
  margin-top: 1rem;
}

.form input[type='text'],
.form input[type='textarea'],
.form input[type='email'],
.form input[type='password'],
.form input[type='tel'],
.form input[type='number'],
.form input[type='file'],
.form select {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

input[type='date'] {
  height: 57px;
  padding: 1rem;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #212121;
  border-radius: 0.4rem;
}

.labelText {
  color: #757575;
  font-size: 12px;
  letter-spacing: 0.39996px;
  line-height: 19.92px;
  text-align: left;
  word-spacing: 0px;
  margin-left: 20px;
}

/* input:focus {
  outline: none !important;
  border: 1px solid var(--primary-color) !important;
  box-shadow: 0 0 10px #b3e6fa;
} */

/* textarea:focus {
  outline: none !important;
  border: 1px solid var(--primary-color) !important;
  box-shadow: 0 0 10px #b3e6fa;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.form input[type='submit'],
button {
  font: inherit;
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}
.form .social-input i.fa-facebook {
  color: #3b5998;
}
.form .social-input i.fa-instagram {
  color: #3f729b;
}
.form .social-input i.fa-youtube {
  color: #c4302b;
}
.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--primary-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

/* Landing Page */
.landing {
  position: relative;
  background: url('./img/showcase.jpg') no-repeat center center/cover;
  height: 100vh;
}

.landing-inner {
  color: #fff;
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Profiles Page */
.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  align-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
  display: grid;
  grid-template-areas:
    'top top'
    'about about'
    'exp edu'
    'github github';
  grid-gap: 1rem;
}

.profile-top {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-top img {
  width: 250px;
}

.profile-top .icons a {
  color: #fff;
  margin: 0 0.3rem;
}

.profile-top .icons a:hover {
  color: var(--dark-color);
}

.profile-about {
  grid-area: about;
  text-align: center;
}

.profile-about .skills {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-exp {
  grid-area: exp;
}

.profile-edu {
  grid-area: edu;
}

.profile-exp h2,
.profile-edu h2,
.profile-add h2,
.profile-marks h2 {
  margin-bottom: 1rem;
}

.profile-exp > div,
.profile-edu > div,
.profile-add > div,
.profile-marks > div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: #ccc 1px dotted;
}

.profile-exp > div:last-child,
.profile-edu > div:last-child,
.profile-marks > div:last-child,
.profile-add > div:last-child {
  border: 0;
}

.profile-exp p,
.profile-edu p,
.profile-marks p,
.profile-add p {
  margin: 0.5rem 0;
}

.profile-github {
  grid-area: github;
}

.profile-github .repo {
  display: flex;
}

.profile-github .repo > div:first-child {
  flex: 7;
  flex-basis: 70%;
}

.profile-github > div:last-child {
  flex: 3;
  flex-basis: 20%;
}

/* Posts Page */
.post-form .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: grid;
  grid-template-columns: 5fr;
  /* grid-gap: 2rem; */
  align-items: center;
}

/* .post > div:first-child {
  text-align: center;
} */

.post img {
  width: 100px;
}

.post .comment-count {
  background: var(--light-color);
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.post .post-date {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.p-subtext {
  color: #aaa;
}

.hide-lg {
  display: none;
}

/* Mobile Styles */
@media (max-width: 700px) {
  .container {
    margin-top: 6.6rem;
    padding: 0 1rem;
  }

  .hide-sm {
    display: none;
  }

  .hide-lg {
    display: block;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Margin */
  .mt-10 {
    margin-top: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .p-m-1 {
    padding: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }

  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profile ul {
    display: none;
  }

  /* Profile Page */

  .profile-top img,
  .profile img {
    width: 200px;
    margin: auto;
  }

  .profile-grid {
    grid-template-areas:
      'top'
      'about'
      'exp'
      'edu'
      'github';
  }

  .profile-about .skills {
    flex-direction: column;
  }

  .dash-buttons a {
    display: block;
    width: 100%;
    margin-bottom: 0.2rem;
  }

  .post {
    grid-template-columns: 5fr;
  }
  .post a,
  .post button {
    padding: 0.3rem 0.4rem;
  }
}
